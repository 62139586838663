
import { Cart } from '@/dto/Cart'
import { CouponRequest } from '@/dto/Coupon'
import { CartModel, CouponModel } from '@/models'
import TransactionModel from '@/models/TransactionModel'
import { defineComponent } from 'vue'
import { moneyFormat } from '@/utils/Util'
import Auth from '@/utils/Auth'
import { Payment } from '@/dto'
import ModalDelete from '@/main/components/Modals/ModalDelete.vue'
import ClassroomModel from '@/models/ClassroomModel'

export default defineComponent({
  name: 'CheckoutWebinar',
  components: {
    ModalDelete,
  },
  data() {
    return {
      slug: this.$route.params.slug as string,
      checkAll: false,
      code: '' as string,
      items: [] as Cart[],
      subtotal: 0,
      subtotalNormal: 0,
      total: 0,
      discount: 0,
      discountWord: '' as string,
      selectedItems: [] as Cart[],
      coupon: '' as string,
      process: false,
      paymentCode: '' as string,
      listPaymentManual: [] as Payment[],
      displayModalPaylater: false,
      displayPayment: false,
      showModalDelete: false,
      selectedItem: {} as any,
      useCoupon: false,
      isCouponExist: false,
      alertCoupon: '' as string,
      selectedPayment: {} as any,
      showDetailPayment: false,
      showModalReferral: false,
      referralCode: '' as string,
      buyNow: this.$route.query.next as string,
      course: this.$route.query.course as string,
    }
  },
  computed: {
    // getters: profile loading & data
    getCartData(): Array<Cart> {
      return this.$store.getters['cartStore/getCartData']
    },
    checkFormCoupon(): boolean {
      if (this.coupon) {
        return true
      } else {
        return false
      }
    },
    checkPayment(): boolean {
      if (this.paymentCode) {
        return true
      } else {
        return false
      }
    },
  },
  created() {
    if (this.slug) {
      if (Auth.isLoggedIn()) {
        this.$router.push('')
      } else {
        this.$router.push('')
      }
    } else {
      this.$router.push('/')
    }
  },
  async mounted() {
    this.listPaymentManual = [
      {
        type: 'E-Wallet',
        note:
          'Kamu bisa melakukan transaksi melalui e-wallet yang kamu miliki.',
        payment: [
          {
            account: 'OVO',
            code: 'ovo',
            icon: '/assets/images/payment/logo_ovo.png',
            id: 22,
            instructions: '',
            name: 'OVO',
          },
          {
            account: 'Dana',
            code: 'dana',
            icon: '/assets/images/payment/logo_dana.png',
            id: 23,
            instructions: '',
            name: 'DANA',
          },
          {
            account: 'Gopay',
            code: 'gopay',
            icon: '/assets/images/payment/logo_gopay.png',
            id: 24,
            instructions: '',
            name: 'GOPAY',
          },
          {
            account: 'Link Aja',
            code: 'link-aja',
            icon: '/assets/images/payment/logo_linkaja.png',
            id: 25,
            instructions: '',
            name: 'Link Aja',
          },
          {
            account: 'Shopee Pay',
            code: 'shopeepay',
            icon: '/assets/images/payment/logo_shopeepay.png',
            id: 26,
            instructions: '',
            name: 'Shopee Pay',
          },
        ],
      },
      {
        type: 'Transfer Bank',
        note: 'Kode unik transaksi untuk mempercepat proses verifikasi.',
        payment: [
          {
            account: '1380556667777',
            code: 'mandiritf',
            icon: '/assets/images/payment/logo_mandiri.png',
            id: 19,
            instructions:
              '1. Masukkan kartu ke mesin ATM, pilihlah bahasa masukan kemudian masukkan kode PIN ATM Anda dengan benar.\\n2. Pilihlah menu “Transaksi Lainnya” pada laman menu.\\n3. Pilihlah “Transfer” , lalu pilih “Rekening Mandiri”.\\n4. Masukan Nomor $1 a.n. $3 kemudian pilih Benar.\\n5. Masukan jumlah nominal uang $2 kemudian pilih Benar.\\n6. Mohon periksa kembali informasi nomor rekening tujuan dan nominal uang yang akan ditransfer.\\n7. Kemudian, selembar struk akan keluar dari ATM. Jangan lupa untuk mengunggah bukti struk transaksi ini untuk menyelesaikan transaksi.',
            name: 'Bank Mandiri',
          },
          {
            account: '3948000999',
            code: 'bcatf',
            icon: '/assets/images/payment/logo_bca.png',
            id: 20,
            instructions:
              '1. Masukkan kartu ke mesin ATM, pilihlah bahasa masukan kemudian masukkan kode PIN ATM Anda dengan benar.\\n2. Pilihlah menu “Transfer” dan “Ke Rek BCA”.\\n3. Kemudian, masukkan nomor rekening BCA yang dituju $1 a.n. $3\\n4. Setelah itu, lanjutkan dengan memasukkan nominal sebesar $2.\\n5. Lalu, ada pertanyaan tentang No Referensi. Bagian ini bisa dikosongkan.\\n6. Tekan OK atau Ya.\\n7. Kemudian, selembar struk akan keluar dari ATM. Jangan lupa untuk mengunggah bukti struk transaksi ini untuk menyelesaikan transaksi.',
            name: 'Bank BCA',
          },
        ],
      },
      {
        type: 'Paylater',
        note: 'Pesan sekarang bayar nanti di setiap akhir bulan.',
        payment: [
          {
            account: 'PAYLATER',
            code: 'paylater',
            icon: '/assets/images/payment/paylater.png',
            id: 21,
            instructions: '',
            name: 'Sekolah Desain PAYLATER',
          },
        ],
      },
    ] as any
    if (Object.keys(this.getCartData).length == 0) {
      await this.getCart()
    }
    if (this.buyNow == 'buy-now') {
      if (this.course) {
        const course: any = this.getCartData.filter(
          (el: Cart) => el.code == this.course
        )
        if (course) {
          this.selectedItems.push(course[0])
          this.updateSubtotal()
          this.displayPayment = true
        }
      } else {
        if (this.getCartData.length > 0) {
          this.toggleSelectAll()
          this.displayPayment = true
        }
      }
    }
  },
  methods: {
    async getCart() {
      await this.$store.dispatch('cartStore/fetchCart')
    },
    updateCheckall() {
      if (this.selectedItems.length == this.getCartData.length) {
        this.checkAll = true
      } else {
        this.checkAll = false
      }
      this.discount = 0
      this.discountWord = ''
      this.useCoupon = false
      this.isCouponExist = false
      this.alertCoupon = ''
      if (this.coupon) {
        this.checkCoupon()
      }
      this.updateSubtotal()
    },
    toggleSelectAll() {
      this.checkAll = !this.checkAll
      this.selectedItems = [] as Cart[]
      if (this.checkAll) {
        if (this.getCartData.length > 0) {
          this.getCartData.forEach((el: any) => {
            this.selectedItems.push(el)
          })
        }
        if (this.coupon) {
          this.checkCoupon()
        }
        this.updateSubtotal()
      }
    },
    getClassroomByCode(code: string) {
      for (let i = 0; i < this.items.length; i++) {
        const element = this.items[i]
        if (code == element.code) {
          return element
        }
      }
    },
    updateSubtotal() {
      this.subtotal = 0
      this.subtotalNormal = 0
      this.selectedItems.forEach(el => {
        this.subtotal += el.price
        this.subtotalNormal += el.normal_price
      })
      this.total = this.subtotal - this.discount
    },
    removeFromSelected(code: string) {
      for (let i = 0; i < this.selectedItems.length; i++) {
        const element = this.selectedItems[i]
        if (element.code == code) {
          this.selectedItems.splice(i, 1)
        }
      }
      this.updateSubtotal()
      if (this.selectedItems.length > 0) {
        if (this.coupon != ``) {
          this.checkCoupon()
        }
        return
      }

      this.discount = 0
      this.discountWord = ``
      this.coupon = ``
    },
    async checkCoupon() {
      this.useCoupon = true
      this.isCouponExist = false
      this.alertCoupon = ''
      if (this.coupon == '') {
        this.useCoupon = true
        this.isCouponExist = false
        this.discount = 0
        this.discountWord = ''
        this.updateSubtotal()
        return
      }

      if (this.selectedItems.length == 0) {
        this.alertCoupon = 'Pilih pesanan dulu sebelum pakai kupon'
        return
      }

      const classrooms = [
        {
          code: '' as string,
        },
      ]

      this.selectedItems.forEach((el, key) => {
        classrooms[key] = {
          code: el.code,
        }
      })

      const request = {
        code: this.coupon,
        classrooms: classrooms,
      } as CouponRequest

      await CouponModel.check(request)
        .then(({ data, success }) => {
          if (success) {
            this.useCoupon = true
            this.isCouponExist = true

            this.discount = 0
            this.updateSubtotal()
            this.discount = this.total - data.total
            if (data.price != '') {
              this.discountWord = 'Potongan (' + this.coupon + ')'
            } else {
              this.discountWord =
                'Potongan (' + this.coupon + ') ' + data.percentage + '%'
            }
            this.updateSubtotal()
          }
        })
        .catch(() => {
          this.useCoupon = true
          this.isCouponExist = false
          this.alertCoupon = 'Kupon ' + this.coupon + ' tidak ditemukan'
          this.discount = 0
          this.discountWord = ''
          this.updateSubtotal()
        })
    },
    async checkout() {
      if (this.selectedItems.length <= 0 || this.paymentCode == ``) {
        return
      }

      if (this.paymentCode == `paylater`) {
        let text = ``
        this.selectedItems.forEach((el, key) => {
          if (key > 0) {
            text += `, `
          }
          text += el.name
        })

        const encodedText = encodeURI(
          `Halo, saya ingin membeli kelas ${text} dengan PAYLATER`
        )
        window.location.href = `https://api.whatsapp.com/send/?phone=6285155421080&text=${encodedText}`
        return
      }

      this.process = true

      const {
        data,
        success,
      } = await TransactionModel.addTransactionWithPayment(
        this.selectedItems,
        this.paymentCode,
        this.coupon
      )

      if (success) {
        await this.getCart()
        this.$router.push(`/payment/${data.code}`)
        return
      }

      this.process = false
    },
    moneyFormat(price: number) {
      return moneyFormat(price)
    },
    selectPayment(data: any) {
      this.selectedPayment = data
      this.paymentCode = data.code
      if (this.paymentCode == 'paylater') {
        this.coupon = ``
        this.checkCoupon()
        this.updateSubtotal()
      }
    },
    openDeleteCartItem(data: any) {
      this.selectedItem = data
      this.showModalDelete = true
    },
    closeDeleteCartItem() {
      this.selectedItem = {}
      this.showModalDelete = false
    },
    async deleteCartItem() {
      const { success } = await CartModel.delete(this.selectedItem.code)
      if (success) {
        this.removeFromSelected(this.selectedItem.code)
        this.getCart()
        this.closeDeleteCartItem()
      }
    },
    openModalReferral() {
      this.showModalReferral = true
    },
    closeModalRefferal() {
      this.showModalReferral = false
    },
    closeDetailPayment() {
      this.showDetailPayment = false
    },
    async redirectClassroom(code: string) {
      await ClassroomModel.detailPublicV2(code)
        .then(res => {
          if (res.data.landing_page.includes('https')) {
            window.location.href = res.data.landing_page
          } else {
            this.$router.push('/classroom/' + code)
          }
        })
        .catch(err => {
          this.$router.push('/classroom/' + code)
        })
    },
  },
})
